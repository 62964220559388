import * as React from "react";
import * as ReactDOM from "react-dom";

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/app.css';

import { App } from "./components/App";
import { AppStore } from "./store/app";
import { EventAPIImpl } from "./svc/event";
import { EventListStore } from "./store/event";
import { SeatPickerStore } from "./store/seatPicker";
import { ReservationMakerStore } from "./store/reservation";
import { ReservationAPIImpl } from "./svc/reservation";

const eventApi = new EventAPIImpl();
const resvApi = new ReservationAPIImpl();
const appState = new AppStore();
appState.eventApi = eventApi
appState.resvApi = resvApi
appState.eventListStore = new EventListStore(eventApi);
appState.seatPickerStore = new SeatPickerStore(eventApi);
appState.resvMakerStore = new ReservationMakerStore(resvApi);

ReactDOM.render(
    <App appState={appState} />,
    document.getElementById("appRoot")
);