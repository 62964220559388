import { observable } from "mobx"
import { RemoteResourceStore } from "./utl"
import { EventAPI } from "../api/Event";
import { EventHeaderDto } from "../api/clientTypes";

export class EventHeader {
    @observable extId: string
    @observable label: string
}

export class EventListRemoteResourceStore extends RemoteResourceStore<boolean, EventHeader[]> {
}

export class EventListStore {
    @observable events: EventListRemoteResourceStore = new EventListRemoteResourceStore(this.fetchEvents.bind(this));

    constructor(private eventApi: EventAPI) {

    }

    private async fetchEvents(): Promise<EventHeader[]> {
        return await this.eventApi
            .find()
            .then((r) => r.map((e) => this.dtoToEventHeader(e)))
    }

    dtoToEventHeader(e: EventHeaderDto): EventHeader {
        const result = new EventHeader()
        result.extId = e.id || ""
        result.label = e.label || ""
        return result
    }
}