import * as React from "react";
import { EventHeader, EventListStore } from "../store/event";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import { RemoteResourceState } from "../store/utl";
import { RemoteResourceStateRenderer } from "./RemoteResourceErrorRenderer";


@observer
class EventHeaderListItemRenderer extends React.Component<{ data: EventHeader }, {}> {
    render() {
        return (<li><Link to={"/reservation-pick/" + this.props.data.extId}>{this.props.data.label}</Link></li>)
    }
}

@observer
class EventHeaderListRenderer extends React.Component<{ data: EventHeader[] }, {}> {
    render() {
        return (
            <>
                <h1>Lista wydarzeń:</h1>
                <ul>
                    {this.props.data.map((itm) => <EventHeaderListItemRenderer key={itm.extId} data={itm} />)}
                </ul>
            </>
        );
    }
}

interface EventListPageProps {
    state: EventListStore
}

@observer
export class EventListPage extends React.Component<EventListPageProps, {}> {
    render() {
        return (
            <>
                {this.props.state.events.isStateSuccess && <EventHeaderListRenderer data={this.props.state.events.data} /> ||
                    <RemoteResourceStateRenderer res={this.props.state.events} />}
            </>
        );
    }

    componentDidMount() {
        this.props.state.events.request(true)
    }
}
