import React = require("react");
import { RouteComponentProps, StaticContext, Redirect } from "react-router";
import { SeatPickerStore } from "../../store/seatPicker";
import { observer } from "mobx-react";
import { SeatPickerRenderer } from "./SeatPicker";
import { ReservationMakerStore, SeatPickerStoreReservationRequest } from "../../store/reservation";
import { RemoteResourceStateRenderer } from "../RemoteResourceErrorRenderer";
import { action } from "mobx";

export interface ReservationLoadActionMatchProps {
    eventId: string;
}

export interface ReservationLoadActionProps extends RouteComponentProps<ReservationLoadActionMatchProps> {
    seatPickerStore: SeatPickerStore
    resvMakerStore: ReservationMakerStore
}


@observer
export class ReservationLoadAction extends React.Component<ReservationLoadActionProps> {
    componentDidMount() {
        this.loadEventData();
    }

    componentDidUpdate() {
        this.loadEventData();
    }

    @action
    private loadEventData(): void {
        this.props.resvMakerStore.reset()
        this.props.seatPickerStore.loadEvent(this.props.match.params.eventId)
    }

    render() {
        return <RemoteResourceStateRenderer res={this.props.seatPickerStore.event} />
    }
}

@observer
export class ReservationClearAction extends React.Component<ReservationLoadActionProps> {
    componentDidMount() {
        this.loadEventData();
    }

    componentDidUpdate() {
        this.loadEventData();
    }

    @action
    private loadEventData(): void {
        this.props.resvMakerStore.reset()
        this.props.seatPickerStore.resetEvent()
    }

    render(): JSX.Element {
        return null
    }
}

export interface SeatPickerPageProps {
    seatPickerStore: SeatPickerStore;
    resvMakerStore: ReservationMakerStore;
}

@observer
export class ReservationPickPage extends React.Component<SeatPickerPageProps> {
    constructor(props: SeatPickerPageProps) {
        super(props);
        this.doSubmit = this.doSubmit.bind(this);
        this.doCancel = this.doCancel.bind(this);
    }

    render() {
        const sps = this.props.seatPickerStore;
        const ev = sps.event.data;
        if (ev == undefined) {
            return null;
        }
        const canEdit = this.props.resvMakerStore.isRequestEditable;
        const canSubmit = this.props.resvMakerStore.isRequestEditable && this.props.seatPickerStore.canSubmit;
        return (
            <div>
                <div>
                    <h1>{sps.event.data.label}</h1>
                    <SeatPickerRenderer
                        sps={sps}
                        enabled={canEdit}
                    />
                </div>
                <div>
                    <button type="button" className="btn" disabled={!canEdit} onClick={this.doCancel}>Rezygnuj</button>
                    &nbsp;
                    <button type="button" className="btn btn-primary" disabled={!canSubmit} onClick={this.doSubmit}>Zarezerwuj</button>
                </div>
            </div>
        );
    }

    private doSubmit(): void {
        const req: SeatPickerStoreReservationRequest = {
            eventId: this.props.seatPickerStore.event.data.extId,
            seatIds: this.props.seatPickerStore.pickedSeatIds.slice()
        }
        this.props.resvMakerStore.prepare(req);
    }

    private doCancel(): void {
        this.props.seatPickerStore.resetEvent();
    }

}

