import { observable, computed, action } from "mobx";

export enum RemoteResourceState {
    INIT,
    PENDING,
    SUCCESS,
    FAILURE
}

export class RemoteResourceStore<K, V> {
    private requested: K;
    private resolved: K;

    @observable state: RemoteResourceState = RemoteResourceState.INIT
    @observable error?: any;
    @observable data?: V;

    constructor(private fetch: (key: K) => Promise<V>) { }

    @computed get isPending() {
        return this.state == RemoteResourceState.PENDING;
    }

    @computed get isStateInit() {
        return this.state == RemoteResourceState.INIT;
    }

    @computed get isStateSuccess() {
        return this.state == RemoteResourceState.SUCCESS;
    }

    @computed get isStateResolved() {
        return this.state == RemoteResourceState.SUCCESS || this.state == RemoteResourceState.FAILURE;
    }

    @computed get isStateFailure() {
        return this.state == RemoteResourceState.FAILURE;
    }

    public request(key: K, forceReload?: boolean): Promise<V> {
        if (forceReload || ((key != this.requested) && (key != this.resolved))) {
            this.requested = key;
            this.state = RemoteResourceState.PENDING;
            return this.fetch(key)
                .then((r) => {
                    this.processSuccess(key, r);
                    return Promise.resolve(r);
                })
                .catch((e) => {
                    this.processFailure(key, e);
                    return Promise.reject(e);
                });
        } else {
            return Promise.resolve(this.data);
        }
    }

    @action
    public reset() {
        this.data = undefined;
        this.requested = undefined;
        this.resolved = undefined;
        this.error = undefined;
        this.state = RemoteResourceState.INIT;
    }

    @action
    private processSuccess(key: K, val: V) {
        if (this.requested == key) {
            this.data = val;
            this.resolved = key;
            this.error = undefined;
            this.state = RemoteResourceState.SUCCESS;
        }
    }

    @action
    private processFailure(key: K, error: any) {
        if (this.requested == key) {
            this.data = undefined;
            this.resolved = key;
            this.error = error;
            this.state = RemoteResourceState.FAILURE;
        }
    }
}