import { RemoteResourceStore } from "./utl";
import { ReservationAPI } from "../api/reservation";
import { observable, computed, action } from "mobx";
import { ReservationDto, ReservationGetResp } from "../api/clientTypes";

export interface SeatPickerStoreReservationRequest {
    eventId: string;
    seatIds: string[];
}

export interface Reservation {
    errors?: string[]
    resv?: {
        extId: string
        eventId: string
        seatIds: string[]
    }
    failedSeatIds?: string[]
}

export class ReservationRemoteResourceStore extends RemoteResourceStore<SeatPickerStoreReservationRequest, Reservation> {

    constructor(api: ReservationAPI) {
        super((req) => this.fetchReservation(api, req));
    }

    private fetchReservation(api: ReservationAPI, sps: SeatPickerStoreReservationRequest): Promise<Reservation> {
        const reqDto = this.spsToResvDto(sps);
        return api.postNew(reqDto)
            .then((r) => this.respToResv(r))
            .catch((e) => Promise.reject(e));
    }

    private spsToResvDto(sps: SeatPickerStoreReservationRequest): ReservationDto {
        return sps;
    }

    private respToResv(resp: ReservationGetResp): Promise<Reservation> {
        const resv = resp.resv != undefined ? {
            eventId: resp.resv.eventId,
            extId: resp.resv.id,
            seatIds: resp.resv.seatIds
        } : undefined;
        const result: Reservation = {
            errors: resp.errors,
            resv,
            failedSeatIds: resp.failedSeatIds
        };
        return Promise.resolve(result);
    }
}

export class ReservationMakerStore {
    @observable reservationRequest: SeatPickerStoreReservationRequest
    @observable reservation = new ReservationRemoteResourceStore(this.api)

    constructor(private api: ReservationAPI) {
    }

    @computed
    public get isRequestEditable() {
        return this.reservationRequest == undefined;
    }

    @action
    reset() {
        this.reservation.reset();
        this.reservationRequest = undefined;
    }

    @action
    prepare(reservationRequest: SeatPickerStoreReservationRequest) {
        this.reservation.reset();
        this.reservationRequest = reservationRequest;
    }

    @action
    submit(): Promise<Reservation> {
        return this.reservation.request(this.reservationRequest);
    }


}