import { ReservationAPI } from "../api/reservation";
import Axios from "axios";
import { ReservationDto, ReservationGetResp, ReservationPaymentPutReq } from "../api/clientTypes";

export class ReservationAPIImpl implements ReservationAPI {
    postNew(resv: ReservationDto): Promise<ReservationGetResp> {
        return Axios
            .post("/api/reservations/new", resv)
            .then((r) => {
                return r.data as ReservationGetResp;
            })
            .catch((e) => {
                if (e.response.status == 422) {
                    return Promise.resolve(e.response.data as ReservationGetResp);
                } else {
                    return Promise.reject(e);
                }
            });
    }

    postPayment(req: ReservationPaymentPutReq): Promise<void> {
        return Axios
            .post("/api/reservations/newPayment", req)
            .then((r) => {
                return Promise.resolve();
            })
            .catch((e) => {
                return Promise.reject(e);
            })
    }

}