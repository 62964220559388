import { observable, action, computed } from "mobx";
import { EventAPI } from "../api/Event";
import { RemoteResourceStore } from "./utl";
import { string } from "prop-types";
import { EventSeatDto, EventDto, EventGetOneResp, LayoutDto, LayoutRowDto } from "../api/clientTypes";

export enum SeatPickerSeatState {
    DISABLED,
    RESERVED,
    AVAILABLE
}

export class SeatPickerEventSeat {
    extId: string;
    layoutId: string;
    label: string;
    state: SeatPickerSeatState;
}

export class SeatPickerLayoutItem {
    // if is a label
    label?: string
    // if is a seat
    seatId?: string
    // ref to seat if seat
    seat?: SeatPickerSeat
}

export class SeatPickerLayoutRow {
    @observable items: SeatPickerLayoutItem[] = []
}

export class SeatPickerLayout {
    @observable rows: SeatPickerLayoutRow[] = []

    public updateSeatRefs(seats: SeatPickerSeat[]) {
        const seatMap = new Map<string, SeatPickerSeat>();
        seats.forEach((s) => seatMap.set(s.seat.layoutId, s));
        this.rows.forEach((row) => {
            row.items.forEach((itm) => {
                itm.seat = (itm.seatId != undefined) ? seatMap.get(itm.seatId) : undefined
            })
        })
    }
}

export class SeatPickerEvent {
    @observable label: string;
    @observable extId: string;
    @observable seats: SeatPickerEventSeat[] = [];
    @observable layout?: SeatPickerLayout;

    public getSeat(extId: string): SeatPickerEventSeat | undefined {
        return this.seats.find((s) => s.extId == extId);
    }


}



export class SeatPickerSeat {
    seat: SeatPickerEventSeat;
    // czy wybrane przez uzytkownika
    @observable picked: boolean;
    // czy wczesniej wybrane ale zajete
    @observable rejected: boolean;

    @action
    public actToggle() {
        this.picked = (!this.picked) && this.canPick;
    }

    @computed
    public get canPick() {
        return this.seat.state == SeatPickerSeatState.AVAILABLE;
    }

    @action
    updateFlags() {
        if (this.picked && !this.canPick) {
            this.rejected = true
            this.picked = false
        }
    }
}

export class SeatPickerEventResource extends RemoteResourceStore<string, SeatPickerEvent> {
    constructor(eventApi: EventAPI) {
        super((id) => this.fetchEvent(eventApi, id));
    }

    private fetchEvent(eventApi: EventAPI, eventId: string) {
        return eventApi.get(eventId)
            .then((r) => (r != undefined) ? this.dtoToEvent(r) : undefined);
    }

    private dtoToSeat(dto: EventSeatDto): SeatPickerEventSeat {
        var result = new SeatPickerEventSeat();
        result.extId = dto.id || ""
        result.layoutId = dto.layoutId || ""
        result.label = dto.label || ""
        if (dto.state == "AVAILABLE") {
            result.state = SeatPickerSeatState.AVAILABLE
        } else if (dto.state == "RESERVED") {
            result.state = SeatPickerSeatState.RESERVED
        } else {
            result.state = SeatPickerSeatState.DISABLED;
        }
        return result;
    }

    private dtoToEvent(dto: EventGetOneResp): SeatPickerEvent {
        let result = new SeatPickerEvent();
        if (dto.event) {
            result.label = dto.event.label || "";
            result.extId = dto.event.id || "";
            if (dto.event.seats) {
                result.seats = dto.event.seats.map((s) => this.dtoToSeat(s));
            }
        }
        if (dto.layout) {
            result.layout = this.dtoToLayout(dto.layout)
        }
        return result;
    }

    private dtoToLayout(layout: LayoutDto): SeatPickerLayout {
        var result = new SeatPickerLayout();
        result.rows = layout.rows.map((r) => this.dtoToLayoutRow(r));
        return result;
    }

    private dtoToLayoutRow(r: LayoutRowDto): SeatPickerLayoutRow {
        var result = new SeatPickerLayoutRow;
        if (r.items) {
            r
                .items
                .map((i) => {
                    var r = new SeatPickerLayoutItem();
                    r.label = i.label;
                    r.seatId = i.seatId;
                    return r
                })
                .forEach((e) => result.items.push(e));
        }
        return result
    }
}

export class SeatPickerStore {
    @observable event = new SeatPickerEventResource(this.eventApi);
    @observable seats: SeatPickerSeat[] = [];
    @observable layout: SeatPickerLayout = new SeatPickerLayout();

    public constructor(private eventApi: EventAPI) {

    }

    @action
    public loadEvent(extId: string) {
        return this.event.request(extId).then((e) => this.updateFromEvent(e));
    }

    @action
    public forceLoadEvent(extId: string) {
        return this.event.request(extId, true).then((e) => this.updateFromEvent(e));
    }

    @action
    public reloadEvent() {
        if (this.event.data) {
            return this.forceLoadEvent(this.event.data.extId)
        } else {
            return Promise.resolve();
        }
    }

    @action
    public resetEvent() {
        this.event.reset()
        this.seats = []
        this.layout = new SeatPickerLayout()
    }

    @action
    private updateFromEvent(event: SeatPickerEvent | undefined) {
        if (event != undefined) {
            this.updateSeats(event.seats)
            const layout = event.layout || new SeatPickerLayout()
            layout.updateSeatRefs(this.seats)
            this.layout = layout
        } else {
            this.seats = [];
            this.layout = new SeatPickerLayout()
        }
    }

    private updateSeats(eventSeats: SeatPickerEventSeat[]) {
        const inEvent = new Map<string, SeatPickerEventSeat>();
        eventSeats.forEach((s) => inEvent.set(s.extId, s));
        // remove not existing
        this.seats = this.seats.filter((s) => inEvent.has(s.seat.extId));
        const existing = new Map<string, SeatPickerSeat>();
        // update seat pointers
        this.seats.forEach((ss) => {
            ss.seat = inEvent.get(ss.seat.extId);
            ss.updateFlags();
            existing.set(ss.seat.extId, ss);
        });
        // add missing
        eventSeats.filter((s) => !existing.has(s.extId)).forEach((s) => {
            const ss = new SeatPickerSeat()
            ss.seat = s
            this.seats.push(ss)
        })
    }

    @computed
    public get canSubmit() {
        return this.event.isStateSuccess && this.anyPickedSeats;
    }

    @computed
    public get pickedSeatIds(): string[] {
        return this.seats.filter((s) => s.picked).map((s) => s.seat.extId);
    }

    @computed
    public get anyPickedSeats() {
        return this.seats.some((s) => s.picked);
    }

    public getSeat(extId: string): SeatPickerSeat | undefined {
        return this.seats.find((s) => s.seat.extId == extId);
    }

}