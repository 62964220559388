import { observable, action } from "mobx"
import { EventListStore } from "./event";
import { EventAPI } from "../api/Event";
import { SeatPickerStore } from "./seatPicker";
import { ReservationMakerStore } from "./reservation";
import { ReservationAPI } from "../api/reservation";

export class AppStore {
    eventApi: EventAPI
    resvApi: ReservationAPI

    @observable eventListStore: EventListStore
    @observable seatPickerStore: SeatPickerStore
    @observable resvMakerStore: ReservationMakerStore
}
