import { EventAPI } from "../api/event";
import Axios, { AxiosResponse } from "axios";
import { EventGetOneResp, EventGetFindResp } from "../api/clientTypes";

function status404ToUndefined<T>(p: Promise<AxiosResponse>): Promise<T | undefined> {
    return p
        .then((r) => {
            return r.data as T
        })
        .catch((e) => {
            if (e.response && e.response.status == 404) {
                return undefined;
            } else {
                return Promise.reject(e);
            }
        });
}

export class EventAPIImpl implements EventAPI {
    get(eventId: string): Promise<EventGetOneResp | undefined> {
        return status404ToUndefined(Axios.get("/api/events/" + eventId));
    }

    find(): Promise<EventGetFindResp> {
        return Axios
            .get("/api/events")
            .then((r) => {
                return r.data;
            })
    }
}