import { RemoteResourceStore, RemoteResourceState } from "../store/utl";
import React = require("react");
import { string } from "prop-types";

export interface RemoteResourceStateRendererProps {
    res: RemoteResourceStore<any, any>
}

export class RemoteResourceStateRenderer extends React.Component<RemoteResourceStateRendererProps> {
    render() {
        const s = this.props.res.state;
        if (s == RemoteResourceState.PENDING) {
            return <div>...</div>
        }
        else if (s == RemoteResourceState.FAILURE) {
            const errStr = this.anyToString(this.props.res.error);
            return <div className="alert alert-danger" role="alert">{errStr}</div>
        }
        else {
            return null;
        }
    }

    private anyToString(e: any): string {
        if ((e == undefined) || (e == null)) {
            return ""
        }
        else if (typeof (e) == 'string') {
            return e
        }
        else if (Array.isArray(e)) {
            return e.map((ei) => this.anyToString(ei)).join("\n")
        } else {
            return JSON.stringify(e)
        }
    }
}