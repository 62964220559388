import React = require("react");
import { observer } from "mobx-react";
import { ReservationMakerStore } from "../../store/reservation";
import { SeatPickerStore, SeatPickerSeat } from "../../store/seatPicker";
import { RemoteResourceStateRenderer } from "../RemoteResourceErrorRenderer";
import { Link } from "react-router-dom";
import { ReservationAPI } from "../../api/reservation";
import { action } from "mobx";
import { ReservationPaymentPutReq } from "../../api/clientTypes";

export interface ReservationPlacedPageProps {
    sps: SeatPickerStore
    resvMakerStore: ReservationMakerStore
    resvAPI: ReservationAPI
}

@observer
export class ReservationPlacedPage extends React.Component<ReservationPlacedPageProps> {
    constructor(props: ReservationPlacedPageProps) {
        super(props);
        this.backBtnClick = this.backBtnClick.bind(this);
    }

    public render() {
        const r = this.props.resvMakerStore.reservation;
        if (r.isStateSuccess && !r.data.errors) {
            return <ReservationRequestSuccessRenderer {...this.props} />
        }
        else if (r.isStateSuccess && r.data.errors) {
            return <>
                <ReservationRequestFailedRenderer
                    seatPickerStore={this.props.sps}
                    errors={r.data.errors}
                    failedSeatIds={r.data.failedSeatIds} />
                <button type="button" className="btn" onClick={this.backBtnClick}>Wróć do wyboru</button>
            </>
        }
        else if (r.isStateFailure) {
            return <RemoteResourceStateRenderer res={r} />
        } else {
            return null;
        }
    }

    private backBtnClick() {
        this.props.sps.reloadEvent()
            .then(() => this.props.resvMakerStore.reset())
    }
}

class ReservationRequestSuccessRenderer extends React.Component<ReservationPlacedPageProps> {
    public constructor(props: ReservationPlacedPageProps) {
        super(props)
        this.btnPaymentClick = this.btnPaymentClick.bind(this)
    }

    public render() {
        const r = this.props.resvMakerStore.reservation.data.resv;
        return (
            <>
                <div>
                    <h1>Rezerwacja potwierdzona</h1>
                    Numer rezerwacji: {r.extId}
                </div>
                <div>
                    <Link to="/">Wróć na początek</Link>
                </div>
                <div>
                    <button type="button" className="btn btn-warning" onClick={this.btnPaymentClick}>Oznacz jako opłaconą</button>
                </div>
            </>
        );
    }

    @action
    private btnPaymentClick() {
        const pyt: ReservationPaymentPutReq = {
            paymentId: this.props.resvMakerStore.reservation.data.resv.extId + "P",
            reservationId: this.props.resvMakerStore.reservation.data.resv.extId
        }
        this.props.resvAPI.postPayment(pyt)
    }
}

interface ReservationRequestFailedRendererProps {
    seatPickerStore: SeatPickerStore
    errors: string[]
    failedSeatIds: string[]
}

function ReservationRequestFailedRenderer(props: ReservationRequestFailedRendererProps): JSX.Element {
    const errorMsg = props.errors.join("\n")
    const failedSeats = props.failedSeatIds ? props.failedSeatIds.map((id) => props.seatPickerStore.getSeat(id)).filter((s) => s != undefined) : []
    return (
        <>
            <h1>Błąd rezerwacji</h1>
            <div>{errorMsg}</div>
            {(failedSeats.length != 0) && <FailedSeatsRenderer failedSeats={failedSeats} />}

        </>
    );
}

function FailedSeatsRenderer(props: { failedSeats: SeatPickerSeat[] }): JSX.Element {
    return (
        <>
            <h2>Miejsca niedostępne:</h2>
            <ul>
                {props.failedSeats.map((s) => <li key={s.seat.extId}>{s.seat.label}</li>)}
            </ul>
        </>
    )
}