import React = require("react");
import { SeatPickerStore, SeatPickerSeat, SeatPickerSeatState, SeatPickerLayoutRow, SeatPickerLayoutItem } from "../../store/seatPicker";
import { observer } from "mobx-react";

interface SeatPickerSeatRendererProps {
    seat: SeatPickerSeat
    enabled: boolean
}

@observer
class SeatPickerSeatRenderer extends React.Component<SeatPickerSeatRendererProps, {}> {
    constructor(props: SeatPickerSeatRendererProps) {
        super(props);
        this.seatOnClick = this.seatOnClick.bind(this);
    }

    render() {
        const s = this.props.seat;
        let stateText = ""
        let className = "seat-picker-box seat-picker-seat ";
        switch (s.seat.state) {
            case SeatPickerSeatState.AVAILABLE:
                className += s.picked ? "seat-picker-seat-picked" : "seat-picker-seat-available";
                stateText = s.picked ? "wybrane" : "dostępne";
                break;
            case SeatPickerSeatState.DISABLED:
                className += "seat-picker-seat-disabled";
                stateText = "niedostępne";
                break;
            case SeatPickerSeatState.RESERVED:
                className += s.rejected ? "seat-picker-seat-rejected" : "seat-picker-seat-reserved";
                stateText = "zajęte";
                break;
        };
        const a = s.seat.state == SeatPickerSeatState.AVAILABLE;
        const aId = "a" + s.seat.extId;
        return (
            <div className={className} onClick={this.seatOnClick} >
                {a &&
                    <>
                        <a id={aId} className={className} href="#" onClick={this.seatOnClick} aria-label={"Wybór miejsca"}>{s.seat.label}</a>
                        <label htmlFor={aId} className="reader-only">{": " + stateText}</label>
                    </> ||
                    <>
                        <span id={aId} className={className}>{s.seat.label}</span>
                        <label htmlFor={aId} className="reader-only">{": " + stateText}</label>
                    </>
                }
            </div>
        )
    }

    private seatOnClick(e: React.MouseEvent<HTMLElement>) {
        if (this.props.enabled) {
            e.stopPropagation();
            this.props.seat.actToggle();
        }
    }
}

function renderSeatPickerLabel(label: string) {
    return <div className="seat-picker-box seat-picker-label">{label}</div>
}

@observer
class SeatPickerItemRenderer extends React.Component<{ item: SeatPickerLayoutItem, enabled: boolean }, {}>{
    render() {
        const item = this.props.item;
        if (item.seat != undefined) {
            return <SeatPickerSeatRenderer seat={item.seat} enabled={this.props.enabled} />
        } else if (item.label != undefined) {
            return renderSeatPickerLabel(item.label)
        } else {
            return null
        }
    }
}

@observer
class SeatPickerRowRenderer extends React.Component<{ row: SeatPickerLayoutRow, enabled: boolean }, {}> {
    render() {
        return (
            <div className="seat-picker-row">
                {this.props.row.items.map((item, idx) => <SeatPickerItemRenderer key={idx} item={item} enabled={this.props.enabled} />)}
            </div>
        )
    }
}

interface SeatPickerRendererProps {
    sps: SeatPickerStore;
    enabled: boolean;
}

@observer
export class SeatPickerRenderer extends React.Component<SeatPickerRendererProps, {}> {
    render() {
        const sps = this.props.sps;
        return (
            <div>
                {sps.layout.rows.map((row, idx) =>
                    <SeatPickerRowRenderer key={idx} row={row} enabled={this.props.enabled} />
                )}
            </div>
        )
    }

}
