import React = require("react");
import { observer } from "mobx-react";
import { ReservationMakerStore, SeatPickerStoreReservationRequest } from "../../store/reservation";
import { SeatPickerEvent, SeatPickerSeat, SeatPickerStore } from "../../store/seatPicker";
import { RemoteResourceStateRenderer } from "../RemoteResourceErrorRenderer";

export interface ReservationConfirmationPageProps {
    sps: SeatPickerStore
    resvMakerStore: ReservationMakerStore
}

@observer
export class ReservationConfirmPage extends React.Component<ReservationConfirmationPageProps> {
    constructor(props: ReservationConfirmationPageProps) {
        super(props);
        this.cancelBtnClick = this.cancelBtnClick.bind(this);
        this.submitBtnClick = this.submitBtnClick.bind(this);
    }

    public render() {
        const btnEnabled = !this.props.resvMakerStore.reservation.isPending;
        return (
            <>
                <h1>Proszę potwierdzić wybór</h1>
                <ReservationRequestSeatsRenderer sps={this.props.sps} req={this.props.resvMakerStore.reservationRequest} />
                <button type="button" disabled={!btnEnabled} className="btn" onClick={this.cancelBtnClick}>Wróć do wyboru</button>
                &nbsp;
                <button type="button" disabled={!btnEnabled} className="btn btn-primary" onClick={this.submitBtnClick}>Rezerwuj</button>
                <RemoteResourceStateRenderer res={this.props.resvMakerStore.reservation} />
            </>
        );
    }

    private cancelBtnClick() {
        this.props.resvMakerStore.reservationRequest = undefined;
    }

    private submitBtnClick() {
        this.props.resvMakerStore.submit();
    }
}

interface ReservationRequestSeatsRendererProps {
    sps: SeatPickerStore
    req: SeatPickerStoreReservationRequest
}

class ReservationRequestSeatsRenderer extends React.Component<ReservationRequestSeatsRendererProps> {
    public render() {
        return (
            <ul>
                {this.props.req.seatIds.map((seatId) =>
                    <ReservationRequestSeatRenderer key={seatId} seat={this.props.sps.getSeat(seatId)} />
                )}
            </ul>
        );
    }
}

class ReservationRequestSeatRenderer extends React.Component<{ seat: SeatPickerSeat }> {
    public render() {
        return (
            <li>{this.props.seat.seat.label}</li>
        );
    }
}