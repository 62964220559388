import * as React from "react";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
import { ReservationPickPage, ReservationLoadAction, ReservationClearAction } from "./seatpicker/ReservationPickPage";
import { AppStore } from "../store/app";
import { observer } from "mobx-react";
import { EventListPage } from "./EventListPage";
import { ReservationConfirmPage } from "./reservation/ReservationConfirmPage";
import { ReservationPlacedPage } from "./reservation/ReservationPlacedPage";

export interface AppProps {
    appState: AppStore
}

@observer
export class App extends React.Component<AppProps> {
    constructor(props: AppProps) {
        super(props);
    }

    render() {
        const aps = this.props.appState;
        const hasReservationRequest = aps.resvMakerStore.reservationRequest != undefined;
        const hasEventData = (aps.seatPickerStore.event.isStateSuccess) && (aps.seatPickerStore.event.data != undefined);
        const hasMissingEventData = (aps.seatPickerStore.event.isStateSuccess) && (aps.seatPickerStore.event.data == undefined);
        const hasReservationSuccess = aps.resvMakerStore.reservation.isStateSuccess;
        return (
            <BrowserRouter>
                <div className="container">
                    <Route path="/" exact render={(rp) =>
                        <>
                            <ReservationClearAction
                                {...rp}
                                resvMakerStore={aps.resvMakerStore}
                                seatPickerStore={aps.seatPickerStore}
                            />
                            <EventListPage state={aps.eventListStore} />
                        </>
                    } />
                    <Route path="/reservation-pick/:eventId" exact render={(rp) =>
                        <>
                            {hasMissingEventData && <Redirect to="/" />}
                            {hasEventData ?
                                <Redirect to="/reservation-edit" /> :
                                <ReservationLoadAction
                                    {...rp}
                                    resvMakerStore={aps.resvMakerStore}
                                    seatPickerStore={aps.seatPickerStore}
                                />
                            }
                        </>
                    } />
                    <Route path="/reservation-edit" exact render={(rp) => {
                        return <>
                            {(!hasEventData || hasMissingEventData) &&
                                <Redirect to="/" />
                            }
                            {(hasReservationRequest) &&
                                <Redirect to="/reservation-confirm" />
                            }
                            {hasEventData &&
                                <ReservationPickPage
                                    seatPickerStore={aps.seatPickerStore}
                                    resvMakerStore={aps.resvMakerStore}
                                />
                            }
                        </>
                    }
                    } />
                    <Route path="/reservation-confirm" exact render={(rp) =>
                        <>
                            {(!hasReservationRequest) &&
                                <Redirect to="/reservation-edit" />
                            }
                            {(hasReservationSuccess) && <Redirect to="/reservation-posted" />}
                            {hasReservationRequest &&
                                <ReservationConfirmPage
                                    sps={aps.seatPickerStore}
                                    resvMakerStore={aps.resvMakerStore}
                                />
                            }
                        </>
                    } />
                    <Route path="/reservation-posted" exact render={(rp) =>
                        <>
                            {(!hasReservationRequest) &&
                                <Redirect to="/reservation-edit" />
                            }
                            <ReservationPlacedPage
                                sps={aps.seatPickerStore}
                                resvMakerStore={aps.resvMakerStore}
                                resvAPI={aps.resvApi}
                            />
                        </>
                    } />
                </div>
            </BrowserRouter>
        );
    }


}
